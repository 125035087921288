import { FC } from 'react';
import styles from './FormBtn.module.scss';

interface IInputBtn {
  id?: string;
  type?: string;
  value: string;
}

const FormBtn: FC<IInputBtn> = ({ id, type, value }) => {
  return (
    <div className={styles.formBtn}>
      <input type={type} value={value} id={id} className={`${styles.formBtn__txt}`} />
    </div>
  );
};

export default FormBtn;
