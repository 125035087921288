import { FC } from 'react';
import styles from './Footer.module.scss';

interface IFooterProps {
  extraClassName?: string;
  mailPage?: boolean;
}

const Footer: FC<IFooterProps> = ({ extraClassName, mailPage }) => {
  const getHeadingClassNames = () => (extraClassName ? `${styles.footer} ${styles[`${extraClassName}`]}` : styles.footer);

  return (
    <footer className={getHeadingClassNames()}>
      <div className={styles.copyright}>
        <strong>«Fantasy Worlds»</strong>
        {!mailPage && (
          <span className={styles.copyright_mail}>
            По всем вопросам пишите нам на почту: <a href="mailto:fantasy-worlds.ru@yandex.ru">fantasy-worlds.ru@yandex.ru</a>
          </span>
        )}
      </div>
    </footer>
  );
};

export default Footer;
