import { forwardRef } from 'react';
import { InputHTMLAttributes } from 'react';
import styles from './FormField.module.scss';

interface IFieldProps {
  label: string;
  required?: boolean;
  error?: any;
}
type TypeInputPropsField = InputHTMLAttributes<HTMLInputElement> & IFieldProps;

interface IField extends TypeInputPropsField {}

const FormField = forwardRef<HTMLInputElement, IField>(({ error, label, type, name, id, placeholder, required = true, ...rest }, ref) => {
  return (
    <div className={styles.inputWrap}>
      <label className={styles.inputLabel} htmlFor={id}>
        {label}:{!!required && <span className={styles.inputLabel__star}>*</span>}
      </label>
      <input ref={ref} type={type} name={name} placeholder={placeholder} id={id} {...rest} className={`${styles.input}`} />
      {error && <span className={styles.inputError}>{error.message}</span>}
      {error && error.type === 'maxLength' && <span className={styles.inputError}>Максимальная длинна превышена</span>}
    </div>
  );
});

FormField.displayName = 'FormField';

export default FormField;
