import { FC } from 'react';
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '@/hooks/useAuth';
import { useActions } from '@/hooks/useActions';
import { useGetUrlPath } from '@/hooks/useGetUrlPath';
import { useRedirectToMain } from '@/hooks/useRedirectToMain';
import Cookies from 'js-cookie';
import Link from 'next/link';
import styles from './Header.module.scss';

interface IHeader {
  setAuthActive: (isAuthActive: boolean) => void;
}

const Header: FC<IHeader> = ({ setAuthActive }) => {
  const router = useRouter();

  const [isClient, setIsClient] = useState(false);

  const { user } = useAuth();
  const { logout } = useActions();

  const pageUrl = useGetUrlPath();

  const userName = user?.user_email || user;

  const token = Cookies.get('token');

  const isCabinetPage = pageUrl.includes('/user/');

  const setAuthActiveHandle = () => {
    setAuthActive(true);
  };

  const cabinetOutHandle = () => {
    logout();
    !!isCabinetPage ? router.push('/') : null;
  };

  useEffect(() => {
    setIsClient(true);
  });

  return (
    <header className={styles.header}>
      <div className={styles.b_logo}>
        <a href="/"></a>
      </div>

      <div className={styles.b_auth}>
        {/* {% if app.params.auth %}
              <div className={styles.row_avatar}>
                  <img src="/images/no_avatar.jpg" width="72" height="72" alt=""/>
              </div>
              <div className={styles.row_auth}>
                  <div className={styles.title}>
                      Привет, 
                      {{ app.params.auth.login }}
                  </div>
                  <a href="/logout" className={styles.logout}></a>
              </div>
          {% else %} */}
        <div className={styles.row_avatar}>
          <img src="/images/no_avatar.jpg" width="72" height="72" alt="" />
        </div>

        {isClient && userName && token ? (
          <div className={`${styles.row_auth} ${styles.row_auth_open}`}>
            <Link prefetch={false} href="/user/settings" className={styles.cabinet_btn}>
              {userName.length > 20 ? `${userName.slice(0, 20)}...` : userName}
            </Link>

            <div className={styles.cabinet_controls}>
              <div className={styles.btn_yellow_round}>Загрузить аватар</div>
              <div className={styles.btn_brown_round} onClick={cabinetOutHandle}>
                Выйти
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.row_auth}>
            <div className={styles.title}>Вы не авторизовались</div>
            <div className={styles.btn_brown_round} onClick={setAuthActiveHandle}>
              Войти
            </div>
            <Link prefetch={false} href="/registration" className={styles.row_reg}>
              Зарегистрироваться
            </Link>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
