import { FC } from 'react';
import Script from 'next/script';
import styles from './FooterBanner.module.scss';

const FooterBanner: FC = () => {
  return (
    <>
      <div className={`${styles.rtbBanner} ${styles.rtbBanner_desktop}`}>
        {/* Yandex.RTB R-A-1489660-4 */}
        <div id="yandex_rtb_R-A-1489660-4"></div>
        <Script strategy="lazyOnload">
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1489660-4',
          blockId: 'R-A-1489660-4'})})
        `}
        </Script>
      </div>
      <div className={`${styles.rtbBanner} ${styles.rtbBanner_mobile}`}>
        {/* Yandex.RTB R-A-1489660-5 */}
        <div id="yandex_rtb_R-A-1489660-5"></div>
        <Script strategy="lazyOnload">
          {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1489660-5',
          blockId: 'R-A-1489660-5'})})
        `}
        </Script>
      </div>
    </>
  );
};

export default FooterBanner;
