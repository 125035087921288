import { FC } from 'react';
import Script from 'next/script';
import styles from './TopBanner.module.scss';

const TopBanner: FC = () => {
  return (
    <>
      <>
        <div className={`${styles.rtbBanner} ${styles.rtbBanner_desktop}`}>
          {/* Yandex_rtb_R-A-1489660-3 */}
          <div id="yandex_rtb_R-A-1489660-3"></div>
          <Script strategy="lazyOnload">
            {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1489660-3',
          blockId: 'R-A-1489660-3'})})
        `}
          </Script>
        </div>
        <div className={`${styles.rtbBanner} ${styles.rtbBanner_mobile}`}>
          {/* Yandex_rtb_R-A-1489660-1 */}
          <div id="yandex_rtb_R-A-1489660-1"></div>
          <Script strategy="lazyOnload">
            {`
          window.yaContextCb.push(()=>{
          Ya.Context.AdvManager.render({
          renderTo: 'yandex_rtb_R-A-1489660-1',
          blockId: 'R-A-1489660-1'})})
        `}
          </Script>
        </div>
      </>
    </>
  );
};

export default TopBanner;
