import { FC } from 'react';
import Link from 'next/link';
import styles from './HeaderBanner.module.scss';

const HeaderBanner: FC = () => {
  return (
    <div className={styles.litresHeaderBanner}>
      <Link prefetch={false} className={styles.litresHeaderBanner__link} target="_blank" href="https://www.litres.ru/audiobook/anonimys/delo-boga-plutosa-70325767/?erid=LjN8KRv25">
        <img className={styles.litresHeaderBanner__img} src="/images/banners/26-anonimus-1920х60.jpg" alt="banner" />
      </Link>
      <Link prefetch={false} className={`${styles.litresHeaderBanner__link} ${styles.litresHeaderBanner__link_tablet}`} target="_blank" href="https://www.litres.ru/audiobook/anonimys/delo-boga-plutosa-70325767/?erid=LjN8KRv25">
        <img className={styles.litresHeaderBanner__img} src="/images/banners/26-anonimus-970х90.jpg" alt="banner" />
      </Link>
      <Link prefetch={false} className={`${styles.litresHeaderBanner__link} ${styles.litresHeaderBanner__link_mobile}`} target="_blank" href="https://www.litres.ru/audiobook/anonimys/delo-boga-plutosa-70325767/?erid=LjN8KUGPb">
        <img className={styles.litresHeaderBanner__img} src="/images/banners/29-anonimus-450х100.jpg" alt="banner" />
      </Link>
    </div>
  );
};

export default HeaderBanner;
